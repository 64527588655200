import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now-deprecated
`}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` to the latest
`}<a parentName="p" {...{
        "href": "/components/layout/rails"
      }}><inlineCode parentName="a">{`Primer::Alpha::Layout`}</inlineCode></a>{`
component.`}</p>
    <h2>{`Features and Examples`}</h2>
    <p><inlineCode parentName="p">{`Primer::Alpha::Layout`}</inlineCode>{` deprecates some arguments and also provides a few new
capabilities.`}</p>
    <h3>{`Sidebar width`}</h3>
    <p>{`The deprecated `}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` configures sidebar width as an integer
number of columns. With `}<inlineCode parentName="p">{`Primer::Alpha::Layout`}</inlineCode>{` we set the sidebar width to one
of `}<inlineCode parentName="p">{`:default`}</inlineCode>{`, `}<inlineCode parentName="p">{`:narrow`}</inlineCode>{`, or `}<inlineCode parentName="p">{`:wide`}</inlineCode>{`. Those correspond to numbers that vary
according to the layout's breakpoint; see the `}<a parentName="p" {...{
        "href": "/components/layout/rails#sidebar-widths"
      }}>{`the component's
documentation`}</a>{`
for specific breakpoint widths.`}</p>
    <p>{`For example, to create a narrower sidebar:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::Layout.new) do |component| %>
  <% component.with_main { "Main" } %>
  <% component.with_sidebar(width: :narrow) { "Sidebar" } %>
<% end %>
`}</code></pre>
    <h3>{`Sidebar placement`}</h3>
    <p>{`Sidebar placement was previously limited to either `}<inlineCode parentName="p">{`:left`}</inlineCode>{` or `}<inlineCode parentName="p">{`:right`}</inlineCode>{`. We can
still configure those, but we can also now configure whether the sidebar should
be above or below the main content when breaking into row mode.`}</p>
    <p>{`For example, suppose we previously had a sidebar on the left, and would like it
to be above the main content when the page breaks into row mode.`}</p>
    <p>{`With `}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` we'd probably have something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::LayoutComponent.new(side: :left)) do |component| %>
  <% component.with_sidebar { "Sidebar" } %>
  <% component.with_main { "Main" } %>
<% end %>
`}</code></pre>
    <p>{`When migrating to `}<inlineCode parentName="p">{`Primer::Alpha::Layout`}</inlineCode>{` we might try:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::Layout.new) do |component| %>
  <% component.with_main { "Main" } %>
  <% component.with_sidebar(col_placement: :start, row_placement: :start) { "Sidebar" } %>
<% end %>
`}</code></pre>
    <p>{`To keep the sidebar `}<em parentName="p">{`below`}</em>{` the main content, though, we could've set
`}<inlineCode parentName="p">{`row_placement: :end`}</inlineCode>{`, or hidden it entirely with `}<inlineCode parentName="p">{`row_placement: :none`}</inlineCode>{`.`}</p>
    <h3>{`Reordering keyboard order`}</h3>
    <p>{`We might want to manually set the focus order for keyboard navigation for
accessibility reasons. Under `}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` we might reorder the
components in the source, but `}<inlineCode parentName="p">{`Primer::Alpha::LayoutComponent`}</inlineCode>{` can override that
with the `}<inlineCode parentName="p">{`first_in_source`}</inlineCode>{` argument, which can be either `}<inlineCode parentName="p">{`:main`}</inlineCode>{` or `}<inlineCode parentName="p">{`:sidebar`}</inlineCode>{`.`}</p>
    <p>{`For example, to ensure that keyboard focus always visits the sidebar before the
main content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::Layout.new(first_in_source: :sidebar)) do |component| %>
  <% component.with_main { "Main" } %>
  <% component.with_sidebar { "Sidebar" } %>
<% end %>
`}</code></pre>
    <h3>{`Specific breakpoints to control responsiveness`}</h3>
    <p>{`Where previously `}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` only offered `}<inlineCode parentName="p">{`responsiveness: true`}</inlineCode>{`
to enable a breakpoint, we can now be more specific by setting the
`}<inlineCode parentName="p">{`stacking_breakpoint`}</inlineCode>{` argument to one of `}<inlineCode parentName="p">{`:sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`:med`}</inlineCode>{`, or `}<inlineCode parentName="p">{`:lg`}</inlineCode>{`. Setting a
larger breakpoint will cause the layout to shift into row mode on a wider
screen.`}</p>
    <p>{`For example, to retain columns on a smaller screen:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::Layout.new(stacking_breakpoint: :sm)) do |component| %>
  <% component.with_main { "Main" } %>
  <% component.with_sidebar { "Sidebar" } %>
<% end %>
`}</code></pre>
    <h3>{`Configurable gutter width`}</h3>
    <p><inlineCode parentName="p">{`Primer::Alpha::Layout`}</inlineCode>{` includes a new `}<inlineCode parentName="p">{`gutter`}</inlineCode>{` argument to configure the width
of the gutter between the sidebar and main content, which can be one of `}<inlineCode parentName="p">{`:none`}</inlineCode>{`,
`}<inlineCode parentName="p">{`:condensed`}</inlineCode>{`, `}<inlineCode parentName="p">{`:default`}</inlineCode>{`, or `}<inlineCode parentName="p">{`:spacious`}</inlineCode>{`.`}</p>
    <p>{`For example, to create an especially roomy gutter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::Layout.new(gutter: :spacious)) do |component| %>
  <% component.with_main { "Main" } %>
  <% component.with_sidebar { "Sidebar" } %>
<% end %>
`}</code></pre>
    <h2>{`Arguments`}</h2>
    <p>{`The following arguments are different between `}<inlineCode parentName="p">{`Primer::LayoutComponent`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Primer::Alpha::Layout`}</inlineCode>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::LayoutComponent`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Alpha::Layout`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`responsive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`stacking_breakpoint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When to collapse from columns to rows. Previously a Boolean, now one of `}<inlineCode parentName="td">{`:lg`}</inlineCode>{`, `}<inlineCode parentName="td">{`:med`}</inlineCode>{`, or `}<inlineCode parentName="td">{`:sm`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`first_in_source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Which element to render first in HTML, determining keyboard navigation order. Either `}<inlineCode parentName="td">{`:main`}</inlineCode>{` or `}<inlineCode parentName="td">{`:sidebar`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gutter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount of space between the sidebar and main section. One of `}<inlineCode parentName="td">{`:none`}</inlineCode>{`, `}<inlineCode parentName="td">{`:condensed`}</inlineCode>{`, `}<inlineCode parentName="td">{`:default`}</inlineCode>{`, or `}<inlineCode parentName="td">{`:spacious`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`side`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Which side should the sidebar be on? Previously either `}<inlineCode parentName="td">{`:right`}</inlineCode>{` or `}<inlineCode parentName="td">{`:left`}</inlineCode>{`, now handled by the `}<inlineCode parentName="td">{`col_placement`}</inlineCode>{` and `}<inlineCode parentName="td">{`row_placement`}</inlineCode>{` slots on the sidebar.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sidebar_col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the sidebar in columns. Now handled by the `}<inlineCode parentName="td">{`width`}</inlineCode>{` slot on the sidebar.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The main and sidebar components also have some new, additional slots.`}</p>
    <p>{`New on the `}<inlineCode parentName="p">{`main`}</inlineCode>{` component:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`width`}</inlineCode>{`: One of `}<inlineCode parentName="li">{`:full`}</inlineCode>{`, `}<inlineCode parentName="li">{`:xl`}</inlineCode>{`, `}<inlineCode parentName="li">{`:lg`}</inlineCode>{`, or `}<inlineCode parentName="li">{`:med`}</inlineCode>{`. A `}<inlineCode parentName="li">{`:full`}</inlineCode>{` width will stretch
the main column to cover all available space.`}</li>
    </ul>
    <p>{`New on the `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` component:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`width`}</inlineCode>{`: One of `}<inlineCode parentName="li">{`:default`}</inlineCode>{`, `}<inlineCode parentName="li">{`:narrow`}</inlineCode>{`, or `}<inlineCode parentName="li">{`:wide`}</inlineCode>{`. Replaces the deprecated
`}<inlineCode parentName="li">{`Primer::LayoutComponent#sidebar_col`}</inlineCode>{` argument.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`col_placement`}</inlineCode>{`: Sidebar placement in column mode. One of `}<inlineCode parentName="li">{`:start`}</inlineCode>{` or `}<inlineCode parentName="li">{`:end`}</inlineCode>{`.
Use this in combination with `}<inlineCode parentName="li">{`row_placement`}</inlineCode>{` to duplicate the functionality of
the deprecated `}<inlineCode parentName="li">{`Primer::LayoutComponent#side`}</inlineCode>{` argument.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`row_placement`}</inlineCode>{`: Sidebar placement in row mode. One of `}<inlineCode parentName="li">{`:start`}</inlineCode>{`, `}<inlineCode parentName="li">{`:end`}</inlineCode>{`, or `}<inlineCode parentName="li">{`:none`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Please see the following documentation for complete descriptions and examples.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/layout/rails"
        }}><inlineCode parentName="a">{`Primer::Alpha::Layout`}</inlineCode>{` component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/alpha/layout/default"
        }}><inlineCode parentName="a">{`Primer::Alpha::Layout`}</inlineCode>{` Lookbook examples`}</a></li>
    </ul>
    <p>&nbsp;</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      